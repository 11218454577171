@import url(https://fonts.googleapis.com/css?family=Cabin);.card-container[data-v-0cab6766] {
  display: inline-block;
  position: relative
}

@import './styles/colors.scss';

.App {
  font-family: Cabin,Avenir,Helvetica,Arial,sans-serif;
  color: $color-font;
  a {
    color: $color-anchor;
    text-decoration: none;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
}

.App-header {
  margin: 30px 20px;
  max-width: 500px;
}

.Portfolio {
  max-width: 500px;
  background-color: lighten(#5b5b5b, 60);
  border-radius: 25px;
  padding: 20px 20px 30px 20px;
  @media screen and (max-width: 600px) {
    max-width: 100%;
    padding-left:0;
    padding-right:0;
    border-radius: 0;
  }
}
