@import url(https://fonts.googleapis.com/css?family=Cabin);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card-container[data-v-0cab6766] {
  display: inline-block;
  position: relative; }

.App {
  font-family: Cabin,Avenir,Helvetica,Arial,sans-serif;
  color: #4f5e7b;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; }
  .App a {
    color: #3b465c;
    text-decoration: none; }

.App-header {
  margin: 30px 20px;
  max-width: 500px; }

.Portfolio {
  max-width: 500px;
  background-color: #f4f4f4;
  border-radius: 25px;
  padding: 20px 20px 30px 20px; }
  @media screen and (max-width: 600px) {
    .Portfolio {
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
      border-radius: 0; } }

.ProfileCard__top {
  display: flex;
  flex-direction: row;
  align-items: flex-start; }
  .ProfileCard__top__profile-picture {
    width: 165px;
    border-radius: 25px;
    margin-right: 25px; }
    @media screen and (max-width: 530px) {
      .ProfileCard__top__profile-picture {
        width: 125px; } }
    @media screen and (max-width: 440px) {
      .ProfileCard__top__profile-picture {
        width: 110px; } }
    @media screen and (max-width: 406px) {
      .ProfileCard__top__profile-picture {
        width: 140px;
        margin-right: 15px; } }
    @media screen and (max-width: 372px) {
      .ProfileCard__top__profile-picture {
        width: 105px; } }
    @media screen and (max-width: 340px) {
      .ProfileCard__top__profile-picture {
        width: 90px; } }
  .ProfileCard__top__right {
    text-align: left; }
    .ProfileCard__top__right h1 {
      margin: 0;
      font-size: 36px;
      color: #3b465c; }
      @media screen and (max-width: 440px) {
        .ProfileCard__top__right h1 {
          font-size: 32px; } }
      @media screen and (max-width: 372px) {
        .ProfileCard__top__right h1 {
          font-size: 26px; } }
      @media screen and (max-width: 340px) {
        .ProfileCard__top__right h1 {
          font-size: 22px; } }
    .ProfileCard__top__right p {
      line-height: 1.35; }
    .ProfileCard__top__right__work {
      margin-top: 7px;
      font-size: 22px;
      color: #3b465c; }
      @media screen and (max-width: 440px) {
        .ProfileCard__top__right__work {
          font-size: 18px; } }
      @media screen and (max-width: 340px) {
        .ProfileCard__top__right__work {
          margin-bottom: 10px;
          font-size: 16px; } }
    .ProfileCard__top__right__education {
      margin-top: 14px;
      font-size: 15px; }
    .ProfileCard__top__right__bubbles {
      height: 25px;
      margin-top: 14px;
      display: flex;
      flex-direction: row;
      align-items: flex-start; }
      @media screen and (max-width: 340px) {
        .ProfileCard__top__right__bubbles {
          margin-top: 0px; } }
      .ProfileCard__top__right__bubbles .Bubble {
        margin-right: 5px; }
      .ProfileCard__top__right__bubbles .Bubble--wrap {
        height: 25px; }

.ProfileCard__chips .Chip {
  margin-right: 5px;
  margin-top: 7px; }

.Bubble {
  border-radius: 50%;
  background-color: #3b465c;
  -webkit-transition: all 5000ms ease-in-out;
  transition: all 5000ms ease-in-out; }
  .Bubble.jiggle {
    background-color: #c0c7d6; }

.Chip {
  color: #fff;
  border-radius: 12.5px;
  padding: 0 10px;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 16px;
  background-color: #3b465c; }

.Portfolio {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 540px;
  -webkit-transition: height .5s ease-in-out;
  transition: height .5s ease-in-out;
  overflow: hidden; }
  .Portfolio .PortfolioController {
    margin-bottom: 20px; }
  .Portfolio__slides {
    position: relative;
    min-height: 500px;
    width: 100%; }
    @media screen and (max-width: 600px) {
      .Portfolio__slides {
        max-width: 90%; } }
    @media screen and (max-width: 400px) {
      .Portfolio__slides {
        max-width: 97%;
        min-height: 300px; } }
  .Portfolio__app-video-slide {
    -webkit-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start; }
    .Portfolio__app-video-slide video {
      max-height: 100%;
      border-radius: 15px; }
      @media screen and (max-width: 400px) {
        .Portfolio__app-video-slide video {
          width: 35%; } }
    .Portfolio__app-video-slide__right {
      margin-left: 5%;
      width: 45%;
      display: flex;
      flex-direction: column; }
      .Portfolio__app-video-slide__right h2 {
        margin-top: 0;
        color: #3b465c; }
      @media screen and (max-width: 400px) {
        .Portfolio__app-video-slide__right {
          width: 60%; } }
      .Portfolio__app-video-slide__right__chips {
        width: 100%; }
        .Portfolio__app-video-slide__right__chips .Chip {
          margin-right: 5px;
          margin-top: 7px;
          font-size: 12px; }
    .Portfolio__app-video-slide--wide {
      flex-direction: column;
      padding-right: 5%;
      padding-left: 5%; }
      .Portfolio__app-video-slide--wide video {
        width: 90%; }
      .Portfolio__app-video-slide--wide .Portfolio__app-video-slide__right {
        width: 90%;
        margin-left: 0; }
        .Portfolio__app-video-slide--wide .Portfolio__app-video-slide__right__chips {
          margin-top: 15px; }

.PortfolioController {
  display: flex;
  flex-direction: row; }
  .PortfolioController__item {
    outline: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: 0px;
    background-color: #3b465c;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px; }
    .PortfolioController__item.active {
      font-weight: 600;
      background-color: #272e3d; }
    .PortfolioController__item div {
      width: 15px;
      height: 15px; }
    .PortfolioController__item svg {
      fill: white;
      width: 15px;
      height: 15px; }

