@import './../../styles/colors.scss';

.Portfolio {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 540px;
  transition: height .5s ease-in-out;
  overflow: hidden;

  .PortfolioController {
    margin-bottom: 20px;
  }

  &__slides {
    position: relative;
    min-height: 500px;
    width: 100%;
    @media screen and (max-width: 600px) {
      max-width: 90%;
    }
    @media screen and (max-width: 400px) {
      max-width: 97%;
      min-height: 300px;
    }
  }
  &__app-video-slide {
    transition: opacity 1s ease-in-out;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    video {
      max-height: 100%;
      border-radius: 15px;
      @media screen and (max-width: 400px) {
        width: 35%;
      }
    }
    &__right {
      h2 {
        margin-top:0;
        color: $color-title;
      }
      margin-left: 5%;
      width: 45%;
      @media screen and (max-width: 400px) {
        width: 60%;
      }
      display:flex;
      flex-direction: column;
      &__chips {
        width: 100%;
        .Chip {
          margin-right: 5px;
          margin-top: 7px;
          font-size: 12px;
        }
      }

    }
    &--wide {
      flex-direction: column;
      padding-right: 5%;
      padding-left: 5%;
      video {
        width: 90%;
      }
      .Portfolio__app-video-slide__right {
        width: 90%;
        margin-left:0;
        &__chips {
          margin-top: 15px;
        }
      }
    }
  }
}