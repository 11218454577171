@import './../../styles/colors.scss';

.PortfolioController {
  display: flex;
  flex-direction: row;
  &__item {
    $buttonSize: 30px;
    outline: none;
    border-radius: 50%;
    width: $buttonSize;
    height: $buttonSize;
    border: 0px;
    background-color: $color-main;
    color: white;
    display:flex;
    align-items: center;
    justify-content: center;
    margin-right:10px;
    &.active {
      font-weight:600;
      background-color: darken($color-main, 10);
    }
    div { width: 15px; height: 15px; }
    svg {
      fill: white;
      width: 15px;
      height: 15px;
    }
  }


}

