@import './../../styles/colors.scss';

.ProfileCard {
  &__top {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    &__profile-picture {
      width: 165px;
      border-radius: 25px;
      margin-right: 25px;
      @media screen and (max-width: 530px) {
        width: 125px;
      }
      @media screen and (max-width: 440px) {
        width: 110px;
      }
      @media screen and (max-width: 406px) {
        width:140px;
        margin-right:15px;
      }
      @media screen and (max-width: 372px) {
        width:105px;
      }
      @media screen and (max-width: 340px) {
        width:90px;
      }
    }
    &__right {
      text-align: left;

      h1 {
        margin: 0;
        font-size: 36px;
        color: $color-title;
        @media screen and (max-width: 440px) {
          font-size: 32px;
        }
        @media screen and (max-width: 372px) {
          font-size: 26px;
        }
        @media screen and (max-width: 340px) {
          font-size:22px;
        }
      }

      p {
        line-height: 1.35;
      }

      &__work {
        margin-top: 7px;
        font-size: 22px;
        color: $color-title;
        @media screen and (max-width: 440px) {
          font-size: 18px;
        }
        @media screen and (max-width: 340px) {
          margin-bottom:10px;
          font-size:16px;
        }
      }

      &__education {
        margin-top: 14px;
        font-size: 15px;
      }
      
      &__bubbles {
        height: 25px;
        margin-top: 14px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        @media screen and (max-width: 340px) {
          margin-top: 0px;
        }
        .Bubble {
          margin-right: 5px;
        }
        .Bubble--wrap {
          height: 25px;
        }
      }
    }
  }
  &__chips {
    .Chip {
      margin-right: 5px;
      margin-top: 7px;
    }
  }
}
