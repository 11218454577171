@import './../../styles/colors';

.Chip {
  color: #fff;
  border-radius: 12.5px;
  padding: 0 10px;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 16px;
  background-color: $color-main;
}